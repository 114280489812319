import React from 'react';
import Style from "./Header_Bottom_Brand.module.scss";

const HeaderBottomBrand = () => {
  return (
    <div className={Style.Header__bottom__brand}>
        <p>homepage/brand/h&m home/bedroom</p>
    </div>
  )
}

export default HeaderBottomBrand;